import { ApiResponse } from "../models";
import ApiService from "./service";
import * as http from './http';
import api from "../../../api";



export class CouponService extends ApiService {

    public async customerInfo(data: any) {
        const formData = new FormData();
        formData.append('customerName', ` ${data.customerName}`);
        formData.append('customerEmail', `${data.customerEmail}`);
        formData.append('customerPhone', ` ${data.customerPhone}`);
        formData.append('vinNumber', `${data.vinNumber}`);
        formData.append('irfKey', ` ${data.irfKey}`);
        formData.append('couponType', `${data.couponType}`);
        const url = `${this.apiDomain}/couponirf/customerInfo`;
        const response:any = await http.postAsFormData<ApiResponse<any>>(url, formData);
        return response?.data;
      }

      public async getCouponCard(couponId: any) {
        const languageID = api.getLanguageID();
        const url = `${this.apiDomain}/couponirf/getCustomerInfo/couponID/${couponId}/languageID/${languageID}/`;
        const response = await http.get<ApiResponse<any>>(url);
        return response?.data;
      }

}