// import { clearDataAction, logoutAction } from './reducers';
import api from '../../api';
import { unauthorizedApiResponseInterceptor } from '../api/interceptor';
import { UserType } from '../api/models';
import { AuthService } from '../api/services/auth';

const initialize = (store: any, setToken: (token?: string) => void) => {
  const state = store.getState();
  unauthorizedApiResponseInterceptor(() => {
    // store.dispatch(logoutAction);
    // store.dispatch(clearDataAction());
    api.setUserToken(undefined);
    api.setSalespersonToken(undefined);
    api.setUserType(undefined);
    api.setUserStatus(undefined);
    // window.location.reload();
  }, [AuthService.loginUrl]);

  // setToken(state.authUser.token);
  api.setBrandID(state.brandAndBranch?.selectedBrand?.brandID);
  api.setBranchID(state.brandAndBranch?.selectedBranch?.warehouseID);
  if (state.authUser.userToken) {
    api.setUserToken(state.authUser.userToken);
    // store.dispatch(userActions.fetchProfile());
  }
  if (state.authUser.salesPersonToken) {
    api.setSalespersonToken(state.authUser.salesPersonToken);
  }
  if (state.authUser.userType) {
    api.setUserType(state.authUser.userType);
  }
  if (state.authUser.userStatus) {
    api.setUserStatus(state.authUser.userStatus);
  }
};

export default initialize;
