import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import '../styles/global.css';
import { useAuth } from '../core/hooks';
import { requestForToken, onMessageListener } from '../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguageAction, ILocale, State } from '../core/store';
import 'react-notifications-component/dist/theme.css';
import { Store, ReactNotifications } from 'react-notifications-component';
import i18n from '../i18n';
import api from '../api';
import Routes from './routes/Routes';

(String.prototype as any).toNumber = function () {
  let num = 0;
  try {
    num = parseFloat(this);
  } catch (err) {
    num = 0;
  }
  return num;
};

const App: React.SFC = () => {
  const settings = useSelector((state: State) => state.settings);
  // const {
  //   // getRoles,
  //   userType,
  //   isAuthenticated,
  //   userToken,
  //   salesPersonToken,
  //   isSalesPerson,
  // } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    onLanguageChange(settings.locale);
    api.setLanguageID(settings.locale.languageID);
    // do not remove this setTimeout.
    setTimeout(() => {
      requestForToken(setDeviceToken);
    });
  }, []);
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     // do not remove this setTimeout.
  //     // setTimeout(() => {
  //     //   getRoles();
  //     // });
  //   }
  // }, [userType, isAuthenticated]);
  // const dispatch = useDispatch();
  // const authState = useSelector((state: State) => state.authUser);
  // // useEffect(() => {
  // //   if (!brandState.isBrandAdBranchLoading) {
  // //     getBrandAndBranches(authState?.userToken ?? '');
  // //   }
  // // }, [authState?.userToken, authState.salesPersonToken]);

  const onLanguageChange = (language: ILocale) => {
    console.log(
      '🚀 ~ onLanguageChange ~ language.languageKey:',
      language.languageKey
    );
    i18n.changeLanguage(language.languageKey);
    dispatch(changeLanguageAction(language.languageID));
    const h1 = document.getElementsByTagName('html')[0]; // Get the first <h1> element in the document
    const att = document.createAttribute('dir'); // Create a "class" attribute
    att.value = language.isRtl ? 'rtl' : 'ltr'; // Set the value of the class attribute
    h1.setAttributeNode(att);
  };

  const [deviceToken, setDeviceToken] = useState();

  // const showNotification = (title: string, body: string) => {
  //   Store.addNotification({
  //     title,
  //     message: body,
  //     type: 'success',
  //     insert: 'top',
  //     container: 'top-right',
  //     animationIn: ['animate__animated', 'animate__fadeIn'],
  //     animationOut: ['animate__animated', 'animate__fadeOut'],
  //     dismiss: {
  //       duration: 5000,
  //       onScreen: true,
  //     },
  //   });
  // };

  // onMessageListener().then((payload: any) => {
  //   if (isAuthenticated) {
  //     const newNotificationData: any = payload.data;
  //     if (
  //       (isSalesPerson && newNotificationData.userToken === salesPersonToken) ||
  //       (!isSalesPerson && newNotificationData.userToken === userToken)
  //     ) {
  //       newNotificationData.isRead = false;
  //       showNotification(
  //         payload.notification?.title,
  //         payload?.notification?.body
  //       );
  //     }
  //   }
  // });
  // // useEffect(() => {
  // //   if (deviceToken != null && isAuthenticated) {
  // //     registerDeviceToken(deviceToken);
  // //   }
  // // }, [deviceToken, isAuthenticated]);
  return (
    <div>
      <ReactNotifications />

      {/* <ConfirmationDialogProvider> */}
      <ReduxToastr
        timeOut={6000}
        newestOnTop={false}
        preventDuplicates
        position="bottom-left"
        transitionIn="bounceIn"
        transitionOut="bounceOut"
        progressBar={false}
      />
      <Routes />
      {/* </ConfirmationDialogProvider> */}
    </div>
  );
};

// without using withRouter, route changes only reflect in url but not on page.
export default withRouter(App);
