import {
  ApiResponse,
  ICustomerBulkImportResponse,
  Roles,
  TokenData,
} from '../models';
import { SignUpModel, ISignup, ChangePasswordProps } from '../models/signup';
import * as http from './http';
import ApiService from './service';

export class AuthService extends ApiService {
  static loginUrl = '/user/login';
  static signUpUrl = '/user/signup';
  static forgotUrl = '/user/forgotPassword';
  static resetUrl = '/user/reset';
  static createPass = '/user/createpassword';

  public async login(credentials: { username: string; password?: string }) {
    const url = `${this.apiDomain}${AuthService.loginUrl}`;
    const data = new FormData();
    data.append('userLogin', credentials.username);
    data.append('userPassword', credentials.password || '');
    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      data
    );
    return response.data;
  }
}
