import {
  CouponService,
} from './services';

import { ApiServiceDataStore } from './data';
import { UserType } from './models';
import { AuthService } from './services/auth';

export class CoreApiClient {
  protected store: ApiServiceDataStore;
  public auth: AuthService;
  public coupon: CouponService;

  constructor(apiDomain: string) {
    this.store = {
      apiDomain,
      userToken: undefined,
      salespersonToken: undefined,
      userType: undefined,
      brandID: undefined,
      branchID: undefined,
      languageID: 1,
      userStatus: undefined,

    };
    this.auth = new AuthService(this.store);
    this.coupon = new CouponService(this.store);
  }
  public setUserType(userType?: UserType) {
    this.store.userType = userType;
  }
  public getUserType() {
    return this.store.userType;
  }  
  public getUserStatus() {
    return this.store.userStatus;
  }

  public setUserToken(token?: string) {
    this.store.userToken = token;
  }

  public setUserStatus(userStatus?: string) {    
    this.store.userStatus = userStatus;
  }
  public getUserToken() {
    return this.store.userToken;
  }
  public setSalespersonToken(token?: string) {
    this.store.salespersonToken = token;
  }
  public getSalespersonToken() {
    return this.store.salespersonToken;
  }

  public setBrandID(brandID: string) {
    this.store.brandID = brandID;
  }
  public setBranchID(branchID: string) {
    this.store.branchID = branchID;
  }
  public setLanguageID(languageID?: number) {
    this.store.languageID = languageID ?? 1;
  }

  public getBrandID() {
    return this.store.brandID;
  }
  public getBranchID() {
    return this.store.branchID;
  }
  public getLanguageID() {
    return this.store.languageID;
  }
}
