import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticateRoute from './AuthenticateRoute';
import RedirectIfAuthenticatedRoute from './RedirectIfAuthenticatedRoute';
import { RouteKeys } from './route-keys';
import { UserPermissionTypes } from '../../core/api/models';
import { ContainerLoader } from '../../components/loader';

const Home = lazy(() => import('../home'));
const CouponDetails = lazy(() => import('../coupons/CouponDetails'));
const CouponDetailsGreen = lazy(() => import('../coupons/CouponDetailsGreen'));
class Routes extends React.Component {
  render() {
    return (
      <Suspense fallback={<ContainerLoader height={500} text={'Loading...'} />}>
        <Switch>
          {/* <AuthenticateRoute exact path={RouteKeys.Home} component={Home} /> */}
          <Route exact path={`${RouteKeys.Home}`} component={Home} />
          <Route
            path={`${RouteKeys.CouponDetails}`}
            component={CouponDetails}
          />
          <Route
            path={`${RouteKeys.CouponDetailsGreen}`}
            component={CouponDetailsGreen}
          />
          {/* <Route component={NotFound} /> */}
        </Switch>
      </Suspense>
    );
  }
}

export default Routes;
