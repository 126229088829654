import React from 'react';
import loaderIcon from './../loader.svg';
import { LoaderProps } from '..';

interface ContainerLoaderProps extends LoaderProps {
  height?: number;
  maxHeight?: number;
}
const ContainerLoader: React.SFC<ContainerLoaderProps> = (
  props: ContainerLoaderProps
) => {
  return (
    <div
      className="Loader ContainerLoader"
      style={
        props.maxHeight
          ? { maxHeight: props.maxHeight }
          : { minHeight: props.height || 50 }
      }
    >
      <div className="loader__icon">
        <img src={loaderIcon} alt=".."></img>
        <div className="circle"> </div>
      </div>
      {props.text && <div className="Loader__text">{props.text}</div>}
    </div>
  );
};

export default ContainerLoader;
